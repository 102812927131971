<template>
    <v-row>
        <v-col cols="12" class="mt-2">

            <DelCard class="ml-1 mr-1 mb-2"
                     v-for="(row, i) in delhistory_data.datas"
                     :key="i"
                     :row="row"
                     @childs-event="openDialog"
                     @perma-event="openPermaDialog"
            >
            </DelCard>

        </v-col>

        

        <!-- *************************************************************************** -->
        <!-- Dialog : Del history Confirm Start  *************************************** -->
        <v-dialog
                v-model="del_dialog"
                persistent
                max-width="290"
        >
            <v-card>
                <v-card-title class="text-h5">
                </v-card-title>
                <v-card-text>사용한 기프티콘을 취소하시겠습니까?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1"
                           text
                           @click="cancelDialog"
                    >
                        취소
                    </v-btn>
                    <v-btn color="#5EB4F9"
                           text
                           @click="updateDelList"
                    >
                        확인
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog : LDel history Confirm End  ******************************************* -->
        <!-- ****************************************************************************** -->

        <!-- *************************************************************************** -->
        <!-- Dialog : Permanent Delete Confirm Start  *************************************** -->
        <v-dialog
                v-model="perma_del_dialog"
                persistent
                max-width="290"
        >
            <v-card>
                <v-card-title class="text-h5">
                </v-card-title>
                <v-card-text>영구히 삭제하시겠습니까?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1"
                           text
                           @click="cancelPermaDialog"
                    >
                        취소
                    </v-btn>
                    <v-btn color="#5EB4F9"
                           text
                           @click="deletePermanent"
                    >
                        영구삭제
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog : Permanent Delete Confirm End  ******************************************* -->
        <!-- ****************************************************************************** -->


        <v-overlay :value="loading_bar">
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>

    </v-row>
</template>

<script>
    import axios from '../../../service/axios'

    export default {
        name: 'DelHistory',
        components: {
            DelCard: () => import("@/components/card/DelCardAdm"),
        },
        data(){
            return {
                del_dialog: false,
                perma_del_dialog: false,
                delhistory_data: {
                    datas:[],
                },
                del_item: null,
                loading_bar: null,
            }
        },

        async created () {
            // await this.delHistoryInfo();
            await this.reqLoadMyGift();
            await this.giftSort();
        },

        methods: {
            initDelItem(){
                this.del_item = null;
            },

            formatPrice(value) {
                try {
                    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')+'원';
                }
                catch{
                    return value+'원';
                }
            },

            openDialog(item){
                this.del_item = item;
                this.del_dialog = true;
            },

            openPermaDialog(item){
                console.log('Item..... : ', item)
                this.del_item = item;
                this.perma_del_dialog = true;
            },

            cancelDialog(){
                this.del_dialog = false;
                this.initDelItem();
            },

            cancelPermaDialog(){
                this.perma_del_dialog = false;
                this.initDelItem();
            },

            updateDelList(){
                this.del_dialog = false;
                this.loading_bar = true;

                let options = {
                    _id: this.del_item._id,
                    del_yn: false
                }
                const params = {
                    accessKey: '',
                    options: options
                }
                // if(this.del_item.gift_type == 'product') {
                //     // Didn't apply
                //     // cycon ver : /user/ownProductUpdate 
                //     axios.post('/product/ownProductUpdate', params).then(res => {
                //         this.loading_bar = false;
                //         console.log(res.data.data.item);
                //         this.delhistory_data.datas = [];
                //         // this.delHistoryInfo();
                //         this.reqLoadMyGift();
                //         this.initDelItem();
                //     }).catch(error => {
                //         this.loading_bar = false;
                //         console.log('페스트 오류', error)
                //     });
                // }else 
                if(this.del_item.gift_type == 'mygift'){
                    // cycon ver : /gift/delete 
                    axios.post('/gift/delete', params).then(res =>{
                        this.loading_bar = false;
                        console.log("Delete Item ..........",res.data.data.item);
                        this.delhistory_data.datas = [];
                        // this.delHistoryInfo();
                        this.reqLoadMyGift();
                        this.initDelItem();
                    }).catch(error => {
                        this.loading_bar = false;
                        console.log('페스트 오류', error)
                    });
                }
            },

            deletePermanent(){
                this.perma_del_dialog = false;
                this.loading_bar = true;

                let options = {
                    _id: this.del_item._id,
                    del_yn: false
                }
                const params = {
                    accessKey: '',
                    options: options
                }

                if(this.del_item.gift_type == 'mygift'){
                    // cycon ver : /gift/delete 
                    console.log('Delete Permanently item : ', params)
                    axios.post('/gift/delete_perma', params).then(res =>{
                        this.loading_bar = false;
                        console.log("Delete Item ..........",res.data.data.item);
                        this.delhistory_data.datas = [];
                        // this.delHistoryInfo();
                        this.reqLoadMyGift();
                        this.initDelItem();
                    }).catch(error => {
                        this.loading_bar = false;
                        console.log('페스트 오류', error)
                    });
                }
            },
            
            async delHistoryInfo(){
                this.loading_bar = true;
                const params = {
                    accessKey: this.$cookies.get('token'),
                    options: true
                }
                await axios.post('/api/user/ownProductInfo', params).then(res =>{
                    for(var gift of res.data.data){
                        gift['gift_type'] = 'product';
                        this.delhistory_data.datas.push(gift);
                    }
                }).catch(error=>{
                    console.log('페스트 오류', error)
                });
            },

            async reqLoadMyGift(){
                var options = {
                    del_yn: true
                }
                const params = {
                    accessKey: this.$cookies.get('token'),
                    options: options
                }
                await axios.post('/gift/allUsersDeleteList', params).then(res =>{
                    for(var gift of res.data.data.item){
                        gift['gift_type'] = 'mygift';
                        gift['product_name'] = '개인 보유 기프티콘';
                        gift['sale_price'] = 0;
                        gift['product_img'] = gift['gift_img_url'];
                        this.delhistory_data.datas.push(gift);
                    }
                    this.loading_bar = false;

                }).catch(error=>{
                    this.loading_bar = false;
                    console.log('페스트 오류', error)
                });
            },

            async giftSort(){
                await this.delhistory_data.datas.sort(function (a, b){
                    console.log('sort 시작');
                    if(a.update_date > b.update_date){
                        return -1
                    }else if(a.update_date < b.update_date){
                        return 1
                    }
                    else if(a.update_date == b.update_date && a.update_time > b.update_time){
                        return -1
                    }
                    else{
                        return 1
                    }
                });
            }
        },
    }

</script>

<style lang="scss">
    .table_font{
        font-size: 0.8rem !important;
        letter-spacing: -0.07em;
    }
    .sale_color{
        color: #ff0000;
    }
    .buy_color{
        color: #4caf50;
    }
</style>
